import { createStore } from 'vuex';
// import createPersistedState from 'vuex-persistedstate'
import rootActions from './actions.js';
import rootGetters from './getters.js';
import rootMutations from './mutations.js';
import authenticationModule from './authentication/index.js';
import baseModule from './base/index.js';
import breakoutsModule from './breakouts/index.js';
import chatModule from './chat/index.js';
import clientsModule from './clients/index.js';
import commentsModule from './comments/index.js';
import divisionsModule from './divisions/index.js';
import eventsModule from './events/index.js';
import layoutModule from './layout/index.js';
import organizationsModule from './organizations/index.js';
import profileModule from './profile/index.js';
import notificationsModule from './notifications/index.js';
import reportsModule from './reports/index.js';
import subscriptionsModule from './subscriptions/index.js';
import usersModule from './users/index.js';
import usersGroupsModule from './usersGroups/index.js';
import usersRolesModule from './usersRoles/index.js';
import websocketModule from './websocket/index.js';
import websocketDashboardModule from './websocket-dashboard/index.js';

const store = createStore({
  // plugins: [createPersistedState({
  //     storage: window.sessionStorage,
  //     paths: ['authentication', 'layout', 'notifications']
  // })],
  modules: {
    authentication: authenticationModule,
    base: baseModule,
    breakouts: breakoutsModule,
    chat: chatModule,
    clients: clientsModule,
    comments: commentsModule,
    divisions: divisionsModule,
    events: eventsModule,
    layout: layoutModule,
    notifications: notificationsModule,
    organizations: organizationsModule,
    profile: profileModule,
    reports: reportsModule,
    subscriptions: subscriptionsModule,
    users: usersModule,
    usersGroups: usersGroupsModule,
    usersRoles: usersRolesModule,
    websocket: websocketModule,
    websocketDashboard: websocketDashboardModule
  },
  strict: true,
  state() {
    return {
      now: new Date,
      nowSC: new Date,
      translator: null,
      updateDashboard: false,
      table: {
        data: [],
        totalPages: 1,
        currentPage: 1,
        previousPage: 1,
        nextPage: 2,
        totalItems: 0
      },
      route: {
        to: {
          name: null,
          path: null
        },
        from: {
          name: null,
          path: null
        }
      },
      logo: {
        large: require('@/assets/images/logos/studiocast_light_128_fr.png'),
        small: require('@/assets/images/logos/studiocast_light_80.png')
      },
      language: {
        language: 'fr',
        name: 'Français',
        flag: require('@/assets/images/flags/quebec.svg')
      },
      availableLanguages: [
        {
          flag: require('@/assets/images/flags/canada.svg'),
          language: 'en',
          name: 'English'
        },
        {
          flag: require('@/assets/images/flags/quebec.svg'),
          language: 'fr',
          name: 'Français'
        }
      ]
    };
  },
  actions: rootActions,
  getters: rootGetters,
  mutations: rootMutations
});

export default store;
