//import router from '@/router/index.js';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  // TODO: mettre des try catch partout
  async getUsersGroups(context, payload) {
    try {
      // console.log(context);
      // console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/groups');

      if (typeof payload.page !== 'undefined') {
        fetchUrl.searchParams.set('_page', payload.page);
      }

      if (typeof payload.limit !== 'undefined') {
        fetchUrl.searchParams.set('_limit', payload.limit);
      }

      if (typeof payload.offset !== 'undefined') {
        fetchUrl.searchParams.set('_offset', payload.offset);
      }

      if (typeof payload.sort !== 'undefined') {
        let sortString = '';
        Object.keys(payload.sort).forEach(function (item) {
          if (payload.sort[item].active) {
            sortString += item + ':' + payload.sort[item].order + ',';
          }
        });

        if (sortString !== '') {
          fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
        }
      }

      if (typeof payload.filters !== 'undefined') {
        let filtersString = '';
        payload.filters.forEach((element) => {
          if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
            if (element.value === true) {
              filtersString += element.name + ':1,';
            } else {
              filtersString += element.name + ':0,';
            }
          } else {
            filtersString += element.name + ':' + element.value + ',';
          }
        });

        if (filtersString !== '') {
          fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
        }
      }

      // console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);
      return response;
    } catch (err) {
      console.log(err);
    }

    // let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users');
    // fetchUrl.searchParams.set('_page', payload.page);
    // fetchUrl.searchParams.set('_limit', payload.limit);
    // fetchUrl.searchParams.set('_offset', payload.offset);

    // let sortString = '';
    // Object.keys(payload.sort).forEach(function (item) {
    //   if (payload.sort[item].active) {
    //     sortString += item + ':' + payload.sort[item].order + ',';
    //   }
    // });

    // if (sortString !== '') {
    //   fetchUrl.searchParams.set('_sort', sortString.slice(0, -1));
    // }

    // let filtersString = '';
    // payload.filters.forEach((element) => {
    //   if (element.name === 'state' || element.name === 'locked' || element.name === 'deleted') {
    //     if (element.value === true) {
    //       filtersString += element.name + ':1,';
    //     } else {
    //       filtersString += element.name + ':0,';
    //     }
    //   } else {
    //     filtersString += element.name + ':' + element.value + ',';
    //   }
    // });

    // if (filtersString !== '') {
    //   fetchUrl.searchParams.set('_filter', filtersString.slice(0, -1));
    // }

    // //console.log(fetchUrl.href);

    // const config = {
    //   method: 'get',
    //   url: fetchUrl.href,
    //   headers: {
    //     //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + context.rootState.authentication.userToken
    //   }
    // };

    // const response = await axios(config);

    // //if (response.data.success) {
    //   // // Sets the token time to live in MS (1h)
    //   // //const tokenExpiration = 3600 * 1000;
    //   // const tokenExpiration = 3600 * 1000;
    //   // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
    //   // if (response.data.result === 'Waiting for 2FA code') {
    //   //   context.dispatch('setUserName', {
    //   //     value: payload.email
    //   //   });
    //   //   router.push({
    //   //     name: 'login-2fa',
    //   //     params: {
    //   //       email: payload.email,
    //   //       password: payload.password
    //   //     }
    //   //   });
    //   // } else {
    //   //   context.dispatch('setUser', {
    //   //     userLoggedIn: true,
    //   //     userName: payload.email,
    //   //     userDetails: response.data.result,
    //   //     userToken: response.data.token,
    //   //     userTokenExpiration: tokenExpirationDate
    //   //   });
    //   //   router.push({
    //   //     name: 'dashboard'
    //   //   });
    //   // }
    // //}
    // return response;
  },
  async getUsersGroupsForSearch(context) {
    // console.log(context);
    //console.log(payload);

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/groups?_limit=all');

    const config = {
      method: 'get',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      }
    };

    const response = await axios(config);
    return response;
  },
  async updateUsersGroup(context, payload) {
    //console.log(context);
    //console.log(payload);

    if (typeof payload.state !== 'undefined') {
      if (payload.state === true) {
        payload.state = 1;
      } else {
        payload.state = 0;
      }
    }

    if (typeof payload.locked !== 'undefined') {
      if (payload.locked === true) {
        payload.locked = 1;
      } else {
        payload.locked = 0;
      }
    }

    if (typeof payload.deleted !== 'undefined') {
      if (payload.deleted === true) {
        payload.deleted = 1;
      } else {
        payload.deleted = 0;
      }
    }

    let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/groups/' + payload.uuid);

    const config = {
      method: 'put',
      url: fetchUrl.href,
      headers: {
        //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + context.rootState.authentication.userToken
      },
      data: payload
    };

    const response = await axios(config);

    return response;
  },
  async deleteUsersGroup(context, payload) {
    const userResponse = await Swal.fire({
      title: payload.i18n('t-users-groups-delete-areyousure'),
      text: payload.i18n('t-users-groups-delete-confirm'),
      showCancelButton: true,
      confirmButtonColor: '#1367c8',
      confirmButtonText: payload.i18n('t-yes'),
      cancelButtonColor: '#0ab39c',
      cancelButtonText: payload.i18n('t-cancel')
    });
    if (userResponse.isConfirmed) {
      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/groups/' + payload.id);
      const config = {
        method: 'delete',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };
      const response = await axios(config);
      return response;
    } else {
      return false;
    }
  },
  async cloneUsersGroup(context, payload) {
    const userResponse = await Swal.fire({
      title: payload.i18n('t-users-groups-clone-areyousure'),
      showCancelButton: true,
      confirmButtonColor: '#1367c8',
      confirmButtonText: payload.i18n('t-yes'),
      cancelButtonColor: '#0ab39c',
      cancelButtonText: payload.i18n('t-cancel')
    });
    if (userResponse.isConfirmed) {
      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/groups/' + payload.uuid + '/clone');
      const config = {
        method: 'post',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };
      const response = await axios(config);
      return response;
    } else {
      return false;
    }
  },
  async getUsersGroupByUuid(context, payload) {
    try {
      // console.log(context);
      //console.log(payload);

      let fetchUrl = new URL(process.env.VUE_APP_APIURL + '/users/groups/' + payload.uuid);

      //console.log(fetchUrl.href);

      const config = {
        method: 'get',
        url: fetchUrl.href,
        headers: {
          //'x-studiocast-apikey': process.env.VUE_APP_APIKEY,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + context.rootState.authentication.userToken
        }
      };

      const response = await axios(config);

      //if (response.data.success) {
      // // Sets the token time to live in MS (1h)
      // //const tokenExpiration = 3600 * 1000;
      // const tokenExpiration = 3600 * 1000;
      // const tokenExpirationDate = new Date().getTime() + tokenExpiration;
      // if (response.data.result === 'Waiting for 2FA code') {
      //   context.dispatch('setUserName', {
      //     value: payload.email
      //   });
      //   router.push({
      //     name: 'login-2fa',
      //     params: {
      //       email: payload.email,
      //       password: payload.password
      //     }
      //   });
      // } else {
      //   context.dispatch('setUser', {
      //     userLoggedIn: true,
      //     userName: payload.email,
      //     userDetails: response.data.result,
      //     userToken: response.data.token,
      //     userTokenExpiration: tokenExpirationDate
      //   });
      //   router.push({
      //     name: 'dashboard'
      //   });
      // }
      //}
      return response;
    } catch (err) {
      console.log(err);
    }
  }
};
